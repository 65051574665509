// @ts-check
import React from "react"
import styled from "@emotion/styled"

import {
  borderRadius,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../styles/constants"
import colors from "../styles/colors"
import FullLogo from "./icons/logos"
import { typographyComponents } from "./typography"
import UILink from "./link"
import { LinkedIn, Twitter, Medium, Facebook } from "./icons/brands"
import DemoContext from "../contexts/demo"
import { trackEvent } from "../utils/trackEvent"

const Wrapper = styled.footer`
  background: ${colors.black};
  color: ${colors.white};
  /* relative position so that footer appears above next-gen preview in test-drive */
  position: relative;
  ${DesktopMediaQuery} {
    margin: 0 -4px;
  }
  ${MobileMediaQuery} {
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
    padding: 40px;
  }
`

const MaxContent = styled.div`
  padding: 60px 80px;
  max-width: 1200px;
  margin: 0 auto;

  ${MobileMediaQuery} {
    padding: 0;
  }
`

const Columns = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const LogoWrapper = styled.div`
  flex: 2;

  @media screen and (max-width: 768px) {
    order: 2;
  }
`
const LogoLink = styled(UILink)`
  text-decoration: none;
`
const Logo = styled(FullLogo)`
  width: 120px;
`

const LinkColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`
const ColumnTitle = styled(typographyComponents.caption)`
  margin-bottom: 15px;
  color: ${colors.grey};
`
const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const LinkItem = styled(typographyComponents.caption)`
  margin-bottom: 5px;
  color: ${colors.white};

  &:last-of-type {
    margin-bottom: 0;
  }
`
const Link = styled(UILink)`
  color: inherit;
  text-decoration: ${(/** @type {{ underlined?: boolean }} */ p) =>
    p.underlined ? "underline" : "none"};
  cursor: pointer;
`

const SocialLinks = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`
const SocialLink = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  margin-right: 14px;

  svg {
    width: 20px;
    height: 20px;
  }
`

const LegalWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`
const LegalSpacer = styled.div`
  flex: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
const LegalContent = styled.div`
  flex: 2;
  color: ${colors.white};
`
const BottomCopy = styled(typographyComponents.caption)`
  display: block;
  max-width: 600px;

  ${DesktopMediaQuery} {
    font-size: 12px;
    line-height: 17px;
  }

  ${MobileMediaQuery} {
    max-width: 190px;
    display: flex;
    flex-direction: column;
  }
`

const CompanyCopy = styled(typographyComponents.caption)`
  margin-bottom: 1.2rem;
  display: block;

  ${DesktopMediaQuery} {
    font-size: 12px;
    line-height: 17px;
  }
`

const OnlyDesktop = styled.span`
  margin: 0 0.5rem;
  ${MobileMediaQuery} {
    display: none;
  }
`

const Footer = () => {
  const { open } = React.useContext(DemoContext)
  const onDemoClick = e => {
    open(e)
    trackEvent({
      category: "open_get_a_demo",
      label: "open get a demo link footer",
      siteSection: "footer",
    })
  }
  return (
    <Wrapper>
      <MaxContent>
        <Columns>
          <LogoWrapper>
            <LogoLink to="/" aria-label="Home">
              <Logo color={colors.white} />
            </LogoLink>
          </LogoWrapper>

          <LinkColumn>
            <ColumnTitle as="h4">Product</ColumnTitle>
            <Links>
              <LinkItem as="li">
                <Link to="/product/self-service/">Self-service</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/product/live-support/">Live support</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/why-mavenoid/">Why Mavenoid?</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/pricing/">Pricing</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link onClick={onDemoClick}>Get a demo</Link>
              </LinkItem>
            </Links>
          </LinkColumn>

          <LinkColumn>
            <ColumnTitle as="h4">Learn</ColumnTitle>
            <Links>
              <LinkItem as="li">
                <Link to="/customers/">Customer stories</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="https://blog.mavenoid.com">Blog</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/webinars/">Webinars</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link as="a" target="_blank" to="https://help.mavenoid.com/">
                  Help center
                </Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/roi/">ROI calculator</Link>
              </LinkItem>
            </Links>
          </LinkColumn>

          <LinkColumn>
            <ColumnTitle as="h4">Company</ColumnTitle>
            <Links>
              <LinkItem as="li">
                <Link to="/about/">About us</Link>
              </LinkItem>

              <LinkItem as="li">
                <Link to="/careers/">Careers</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/press/">Press</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/partner-program/">Partners</Link>
              </LinkItem>
              <LinkItem as="li">
                <Link to="/contact-us/">Contact us</Link>
              </LinkItem>
            </Links>
          </LinkColumn>

          <LinkColumn>
            <SocialLinks>
              <SocialLink
                href="https://www.linkedin.com/company/mavenoid/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our LinkedIn account"
                title="LinkedIn"
              >
                <LinkedIn />
              </SocialLink>

              <SocialLink
                href="https://twitter.com/mavenoid"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our twitter account"
                title="Twitter"
              >
                <Twitter />
              </SocialLink>

              <SocialLink
                href="https://www.facebook.com/mavenoid"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our Facebook social account"
                title="Facebook"
              >
                <Facebook />
              </SocialLink>

              <SocialLink
                href="https://medium.com/@mavenoid"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our Medium social account"
                title="Medium"
              >
                <Medium />
              </SocialLink>
            </SocialLinks>
          </LinkColumn>
        </Columns>

        <LegalWrapper>
          <LegalSpacer />
          <LegalContent>
            <CompanyCopy>
              &copy; {new Date().getFullYear()} Mavenoid&nbsp;AB <br />
            </CompanyCopy>
            <BottomCopy>
              <Link to="/terms-of-service/" forceLanguage="en">
                Terms of service
              </Link>
              <OnlyDesktop>•</OnlyDesktop>{" "}
              <Link to="/privacy-policy/" forceLanguage="en">
                Privacy policy
              </Link>
              <OnlyDesktop>•</OnlyDesktop>{" "}
              <Link to="/cookie-policy/" forceLanguage="en">
                Cookie policy
              </Link>
              <OnlyDesktop>•</OnlyDesktop>{" "}
              <Link to="/data-processing-agreement/" forceLanguage="en">
                Data Processing Agreement
              </Link>
            </BottomCopy>
          </LegalContent>
        </LegalWrapper>
      </MaxContent>
    </Wrapper>
  )
}

export default Footer
